import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { TimelineLite } from 'gsap/TweenMax';
import { GlobalDispatchContext } from '../context/GlobalContextProvider';

const Section1 = styled.section`
  min-height: calc(100vh - 75px);
  position: relative;
  padding-top: 75px;
  width: 100%;
  background-color: #0F496F;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.h1`
  position: relative;
  font-family: 'Lato Hairline', 'Lato', 'Arials', sans-serif;
  font-size: 60rem;
  color: #eee;
  top: -85px;
  opacity: 0;
  text-align: center;
  
  @media screen and (max-width: 675px) {
    font-size: 50rem;
  }
  
  @media screen and (max-width: 500px) {
    font-size: 40rem;
  }
`;

const ContactButton = styled.div`
  position: relative;
  background-color: #eee;
  top: -85px;
  opacity: 0;
  transition: background-color .2s linear;
  
  &:hover {
    background-color: #F0B67F;
  }
`;

const ButtonText = styled.a`
  font-family: 'Lato', sans-serif;
  font-size: 30rem;
  color: #0F496F;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  
  box-sizing: border-box;
  padding: 15px 0;
  width: 350px;
  text-align: center;
  
  @media screen and (max-width: 675px) {
    width: 300px;
    font-size: 25rem;
  }
  
  @media screen and (max-width: 500px) {
    width: 200px;
    font-size: 20rem;
  }
`;


const ElectrodePage = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const elements = [];

  useEffect(() => {
    dispatch({ type: 'NAV_COLOR_CHANGE', color: 'rgba(54, 54, 54, 0)' });
    let tween = new TimelineLite();
    tween.staggerTo(elements, 0.3, {
        delay: 0.1,
        autoAlpha: 1,
        y: -10,
        overwrite: false,
      }, 0.15,
    );
  }, []);

  return (
    <Layout>
      <SEO
        title="Contact"
        description="Get in touch with us"
      />
      <Section1>
        <Header ref={e => elements[0] = e}>
          How can we help you?
        </Header>
        <ContactButton ref={e => elements[1] = e}>
          <ButtonText href="mailto:info@ceramark.com">
            Get in touch
          </ButtonText>
        </ContactButton>
      </Section1>
    </Layout>
  );
};

export default ElectrodePage;
